import Vue from 'vue';

import Vuetify from 'vuetify';
import VueMasonry from 'vue-masonry-css';
import InfiniteLoading from 'vue-infinite-loading';
import VueResource from 'vue-resource';
import VueImg from 'v-img';



const router = new VueRouter({mode: 'history'})
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";
import viewerImage from '../components/ViewerImage.vue';
import axios from 'axios'
import VueFormulate from '@braid/vue-formulate'
import VueLetterAvatar from 'vue-letter-avatar';
import shareIt from 'vue-share-it';

Vue.use(axios)
Vue.use(VueFormulate)
Vue.use(shareIt);
Vue.use(VueLetterAvatar);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)


import './../../vuejs/vue-go-top.min';

function vueInit(elementId, options) {
    const element = document.getElementById(elementId);
    if (element) {
        options.el = element;
        new Vue(options).$mount('#'.elementId);
    }
}
Vue.use(Vuetify, {
    iconfont: 'md',
})
Vue.use(GoTop);
Vue.use(VueMasonry);
Vue.use(InfiniteLoading);
Vue.use(VueResource);
Vue.use(VueImg);


import portfolioImagesHome from '../components/portfolio/ImageHome.vue';
import portfolioImagesGallery from '../components/portfolio/ImagesGallery.vue';


vueInit("portfolioImagesHome", {
    vuetify: new Vuetify(),
    GoTop,
    VueMasonry,
    VueResource,
    VueImg,
    router,
    BootstrapVue,
    BootstrapVueIcons,
    render: h => h(portfolioImagesHome,{
        props: {
            cols: document.getElementById("portfolioImagesHome").getAttribute("data-cols-value"),
            gutter: document.getElementById("portfolioImagesHome").getAttribute("data-gutter-value")
        }
    })
})

vueInit("portfolioImagesGallery", {
    vuetify: new Vuetify(),
    GoTop,
    VueMasonry,
    VueResource,
    VueImg,
    router,
    BootstrapVue,
    BootstrapVueIcons,
    render: h => h(portfolioImagesGallery,{
        props: {
            cols: document.getElementById("portfolioImagesGallery").getAttribute("data-cols-value"),
            gutter: document.getElementById("portfolioImagesGallery").getAttribute("data-gutter-value")
        }
    })
})

import Embed from 'v-video-embed';
Vue.use(Embed);
import portfolioVideo from '../components/portfolio/video.vue';
vueInit("portfolioVideo", {
    vuetify: new Vuetify(),
    GoTop,
    Embed,
    render: h => h(portfolioVideo)
})

import designPortfolioImagesHome from '../components/portfolio/designs/ImageHome.vue';
vueInit("designPortfolioImagesHome", {
    vuetify: new Vuetify(),
    GoTop,
    VueMasonry,
    VueResource,
    VueImg,
    render: h => h(designPortfolioImagesHome)
})

import designPortfolioVideo from '../components/portfolio/designs/video.vue';
import VueRouter from "vue-router";
vueInit("designPortfolioVideo", {
    vuetify: new Vuetify(),
    GoTop,
    Embed,
    render: h => h(designPortfolioVideo)
})

vueInit("viewerImage", {
    vuetify: new Vuetify(),
    GoTop,
    VueResource,
    VueImg,
    axios,
    router,
    BootstrapVue,
    BootstrapVueIcons,
    VueFormulate,
    VueLetterAvatar,
    shareIt,
    render: h => h(viewerImage,
        {
            props: {
                image: document.getElementById("viewerImageDatas").getAttribute("image"),
            }
        })
})


