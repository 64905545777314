var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("go-top", {
        attrs: {
          "bg-color": "#36b5aa",
          src: "/assets/img/icon-backtotop.png",
          size: 50,
          "has-outline": false,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { "infinite-wrapper": "" } },
        [
          _c(
            "masonry",
            {
              attrs: {
                id: "infinite-list",
                cols: { default: 4, 1000: 3, 700: 2, 400: 1 },
                gutter: { default: "30px", 700: "15px" },
              },
            },
            _vm._l(_vm.images, function (image, $index) {
              return _c(
                "div",
                { key: $index, staticClass: "margin-lg-30b" },
                [
                  _c("v-img", {
                    staticClass: "grey lighten-2",
                    attrs: {
                      alt: image.title,
                      src: image.thumb_path,
                      "lazy-src": image.thumb_path,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "placeholder",
                          fn: function () {
                            return [
                              _c(
                                "v-row",
                                {
                                  staticClass: "fill-height ma-0",
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "grey lighten-5",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("infinite-loading", { on: { infinite: _vm.infiniteHandler } }, [
            _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }),
            _vm._v(" "),
            _c("div", { attrs: { slot: "no-results" }, slot: "no-results" }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }