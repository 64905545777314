<template>
  <div>
    <go-top
        bg-color="#36b5aa"
        src="/assets/img/icon-backtotop.png"
        :size="50"
        :has-outline="false"
    ></go-top>
    <div infinite-wrapper>
      <masonry id="infinite-list"
               :cols="{ default: 4, 1000: 3, 700: 2, 400: 1 }"
               :gutter="{ default: '30px', 700: '15px' }"
      >
        <div
            v-for="(image, $index) in images"
            :key="$index"
            class="margin-lg-30b"
        >
          <v-img
              class="grey lighten-2"
              :alt="image.title"
              :src="image.thumb_path"
              :lazy-src="image.thumb_path"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </masonry>
      <infinite-loading @infinite="infiniteHandler">
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
const routes = require("../../../../../public/js/fos_js_routes.json");
import Routing from "../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

Routing.setRoutingData(routes);
import VLazyImage from "v-lazy-image";
import $ from "jquery";
import axios from "axios";

export default {
  components: {
    VLazyImage,
  },
  data: function () {
    return {
      images: [],
      total: 0,
      page: 1,
      loading: true,
    };
  },
  methods: {
    infiniteHandler($state) {
      const api = Routing.generate("design_portfolio_medias", {
        name: name,
        page: this.page,
      });

      axios.get(api).then(({data}) => {
        if (data.images.length > 0) {
          this.page += 1;
          this.images.push(...data.images);
          if (this.page > data.totalPages) {
            $state.complete();
          }
          $state.loaded();
        } else {
          $state.complete();
        }
      });

    },
  },
};
</script>